import React, { useState } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"

import NodeLink from "../utilities/nodeLink"

import { processFullNetwork } from "../components/viz/processNetwork3"

import { NetworkTagFilters } from "../components/viz/networkTagFilters"
import { PageHeader, Section, Content } from "../styles/StyledElements"
import { FilteredOutline } from "../components/filteredOutline"

const { slugFormat } = require("../utilities/slugFormat")

const SectionInner = styled.section`
  padding-top: 2rem;
`

export default function Selections({ data }) {
  // console.log("data")
  // console.log(data)
  // let dataset = processNetwork3(data)
  // console.log("dataset")
  // console.log(dataset)

  // console.log("data2")
  // console.log(data.forecasts)
  let dataset = processFullNetwork(data.forecasts.nodes)
  console.log(dataset)

  const [selectedNodes, setSelectedNodes] = useState([])
  const [selectedNodeIds, setSelectedNodeIds] = useState([])
  // const [highlighting, setHighlighting] = useState(false)

  // const filterUpdate = React.useCallback((results, selected) => {
  //   console.log("NODES UPDATE")
  //   console.log({ selected })
  //   setSelectedNodes(results)
  //   setSelectedNodeIds(results.map(d => d.id))
  //   setHighlighting(selected.sectors.length > 0 || selected.tags.length > 0)
  // }, [])

  const filterUpdate = React.useCallback((results, selected) => {
    // console.log("NODES UPDATE")
    // console.log(results)
    setSelectedNodes(results)
    setSelectedNodeIds(results.map(d => d.id))
    // setHighlighting(selected.sectors.length > 0 || selected.tags.length > 0)
  }, [])

  return (
    <Layout>
      <Seo title="Selections" />
      <PageHeader>
        <Content>
          <h1>Selections</h1>
        </Content>
      </PageHeader>
      <Section>
        <Content>
          <SectionInner>
            <NetworkTagFilters
              nodes={dataset.nodes}
              filterUpdate={filterUpdate}
              filterTypes={{ sectors: true, tags: true }}
            ></NetworkTagFilters>
          </SectionInner>
          <SectionInner>
            {/* <NetworkTagListFilter
              nodes={dataset.nodes}
              selectable={true}
              filterUpdate={filterUpdate}
            /> */}
            {/* <TrendList trends={data.trends} ids={selectedNodeIds}></TrendList> */}
            <FilteredOutline
              forecasts={data.forecasts}
              ids={selectedNodeIds}
            ></FilteredOutline>

            {/* <ForecastList forecasts={data.forecasts}></ForecastList> */}
          </SectionInner>
          <SectionInner>
            <h5>Nodes</h5>
            {selectedNodes?.map((node, i) => (
              <div key={`node-${i}`}>
                <Link to={`/${node.type}s/${node.id}`}>
                  <span>
                    ({node.type}) {node.name}
                  </span>
                </Link>
                <NodeLink name={node.name} id={node.id} type={node.type}>
                  <span>
                    ({node.type}) {node.name}
                  </span>
                </NodeLink>
                <div>{slugFormat(node.name)}</div>
                <div></div>
              </div>
            ))}
          </SectionInner>
        </Content>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    forecasts: allAirtable(filter: { table: { eq: "Forecasts" } }) {
      nodes {
        recordId
        data {
          Name
          Category_Header
          Summary
          Trends {
            recordId
            data {
              Name
              Visibility
              Summary
              STEEP
              Time_Frame
              Certainty
              Impact
              Research_Driver
              Signals {
                recordId
                data {
                  Name
                  Visibility
                  Sector
                  Tags
                }
              }
            }
          }
        }
      }
    }
    trends: allAirtable(filter: { table: { eq: "Trends" } }) {
      nodes {
        recordId
        data {
          Name
          Summary
          STEEP
          Time_Frame
          Certainty
          Impact
          Research_Driver
          Signals {
            recordId
            data {
              Name
              Visibility
              Sector
              Tags
            }
          }
        }
      }
    }
    signals: allAirtable(
      filter: {
        table: { eq: "Signals" }
        #data: { Visibility: { eq: "Published" } }
      }
    ) {
      nodes {
        recordId
        data {
          Name
          Visibility
          Sector
          Tags
        }
      }
    }
  }
`
